import { invokeApig } from '../libs/authLib';
import * as config from '../config';
import axios from 'axios';

class DesignApi {
  // base URI for Cirrus API calls
  static baseUri = config.default.apiGateway.TEMPLATE_DESIGN_API_URL;

  static getDesignApiToken(siteId) {
    const apiUrl = '/users/tdtoken/' + siteId;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'GET' });
  }

  static getAccountTemplatesList(printerName) {
    const utf8Array = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8Array);
    const url = `${this.baseUri}/templates/${printerId}`;
    return axios.post(url, null, {
     // withCredentials: true,
     headers: {designApi: true}
    });
  }

  static getDefaultTemplatesList(printerName) {
    const utf8Array = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8Array);
    const url = `${this.baseUri}/templates/${printerId}/default`;
    return axios.post(url, null, {
     // withCredentials: true,
     headers: {designApi: true}
    });
  }

  static getTemplates(printerName, templateNames) {
    const utf8PrinterNameArray = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8PrinterNameArray);
    const url = `${this.baseUri}/templates/${printerId}/thumbnails`;
    const data = { FileIds: JSON.stringify(templateNames) };
    return axios.post(url, data, {
     // withCredentials: true,
     headers: {designApi: true}
    });
  }

  static getBlankTemplate(printerName, printerFormat) {
    const utf8PrinterNameArray = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8PrinterNameArray);
    const utf8PrinterFormatArray = convertToUTF8Array(printerFormat);
    const formatId = buf2hex(utf8PrinterFormatArray);
    const url = `${this.baseUri}/template/blank/printer/${printerId}/format/${formatId}/thumbnail`;
    return axios.post(url, null, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static getDefaultTemplates(printerName, templateNames) {
    const utf8Array = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8Array);
    const url = `${this.baseUri}/templates/${printerId}/default/thumbnails`;
    const data = { FileIds: JSON.stringify(templateNames) };
    return axios.post(url, data, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static checkTemplateExists(newName: string, printerName: string, printerFormat: string) {
    const utf8NewNameArray = convertToUTF8Array(newName);
    const newNameId = buf2hex(utf8NewNameArray);
    const utf8PrinterNameArray = convertToUTF8Array(printerName);
    const printerId = buf2hex(utf8PrinterNameArray);
    const utf8PrinterFormatArray = convertToUTF8Array(printerFormat);
    const formatId = buf2hex(utf8PrinterFormatArray);
    const url = `${this.baseUri}/template/${newNameId}/printer/${printerId}/format/${formatId}`;
    return axios.get(url, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static createNewTemplateFromBlank(newName: string, printerName: string, printerFormat: string) {
    const utf8NewNameArray = convertToUTF8Array(newName);
    const newNameId = buf2hex(utf8NewNameArray);
    const data = { CreatingNewFile: 'fromBlank', PrinterType: printerName, PrinterFormat: printerFormat };
    const url = `${this.baseUri}/template/${newNameId}`;
    return axios.post(url, data, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static createNewTemplateFromFile(newName: string, fromTemplateName: string) {
    const utf8NewNameArray = convertToUTF8Array(newName);
    const newNameId = buf2hex(utf8NewNameArray);
    const data = { CreatingNewFile: 'fromTemplateFile', TemplateName: fromTemplateName };
    const url = `${this.baseUri}/template/${newNameId}`;
    return axios.post(url, data, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static getPrinterList() {
    const url = `${this.baseUri}/settings/allowedprinters`;
    return axios.post(url, null, {
      // withCredentials: true,
      headers: {designApi: true}
    });
  }

  static deleteTemplate(printerName: string, templateId: string) {
    const utf8PrinterNameArray = convertToUTF8Array(printerName);
    const printerNameId = buf2hex(utf8PrinterNameArray);
    const data = { TemplateId: templateId };
    const url = `${this.baseUri}/templates/${printerNameId}`;
    return axios.delete(url, {
      data,
      // withCredentials: true,
      headers: {designApi: true}
    });
  }
}

function buf2hex(buffer) {
  return Array.prototype.map.call(new Uint8Array(buffer), (x) => `00${x.toString(16)}`.slice(-2)).join('');
}

function convertToUTF8Array(str) {
  const utf8: Array<any> = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode < 0x80) {
      utf8.push(charcode);
    } else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    }
    // surrogate pair
    else {
      i++;
      charcode = ((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff);
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return utf8;
}

export default DesignApi;
